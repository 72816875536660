import { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getParamFromQuery } from '../utils/getParamFromQuery';
import useLocalStorage from './useLocalStorage';
import { isTokenExpired } from '../utils/isTokenExpired';
import FunnelContext from '../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { replaceUrlState } from '../utils/replaceUrlState';

const useAuth = (
  petlabCampaignTokenKey = 'petlab_campaign_token',
  klaviyoUserIdKey = 'utm_id',
  klaviyoCampaignIdKey = 'utm_campaign',
  redirectIfAuthFails = false,
  expiredTokenRedirect = 'https://thepetlabco.com/pages/token-expired',
  useStagingApiEndpoint = false
) => {
  const { setCurrentLayer } = useContext(FunnelContext);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useLocalStorage('authToken', null);

  const endpointApiSite = useStagingApiEndpoint
    ? process.env.GATSBY_ENDPOINT_API_SITE_STAGING
    : process.env.GATSBY_ENDPOINT_API_SITE;

  const getToken = useCallback(async () => {
    const petlabCampaignTokenValue = getParamFromQuery(petlabCampaignTokenKey);
    const klaviyoUserIdValue = getParamFromQuery(klaviyoUserIdKey);
    let klaviyoCampaignIdValue = getParamFromQuery(klaviyoCampaignIdKey).match(
      /\((.*)\)/g
    );

    if (klaviyoCampaignIdValue) {
      klaviyoCampaignIdValue = klaviyoCampaignIdValue[0] || '';
      klaviyoCampaignIdValue = klaviyoCampaignIdValue
        .replace('(', '')
        .replace(')', '');
    }

    try {
      const { data } = await axios.post(
        `${endpointApiSite}/api/campaign/auth`,
        {
          petlab_campaign_token: petlabCampaignTokenValue,
          klaviyo_user_id: klaviyoUserIdValue,
          klaviyo_campaign_id: klaviyoCampaignIdValue
        }
      );
      return JSON?.parse(data);
    } catch (error) {
      if (redirectIfAuthFails && !Builder.isEditing) {
        window.location.href = expiredTokenRedirect;
      } else {
        replaceUrlState(
          `${window.location.origin + window.location.pathname}?layer=error`
        );
        setCurrentLayer('error');
      }
      return error;
    }
  }, [
    expiredTokenRedirect,
    redirectIfAuthFails,
    endpointApiSite,
    klaviyoCampaignIdKey,
    klaviyoUserIdKey,
    petlabCampaignTokenKey,
    setCurrentLayer
  ]);

  useEffect(() => {
    if (token && !isTokenExpired(token)) {
      setLoading(false);
      return;
    }

    const url = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(url);
    const isError = urlParams.get('layer') === 'error';

    if (isError) {
      setLoading(false);
      return;
    }

    getToken().then(results => {
      results?.token && setToken(results.token);
      setLoading(false);
    });
  }, [token, getToken, setToken]);

  return { token, setToken, getToken, loading };
};

export default useAuth;
