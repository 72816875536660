import styled from 'styled-components';

export const Container = styled.div``;

export const Check = styled.div``;

export const CheckPosition = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-size: 18px;
  font-weight: 400;
`;

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  font-family: 'Utopia Std', serif;
  font-size: 20px;
  font-weight: 400;
  border-top: 1px solid #6677aa;
`;

export const Text = styled.span`
  color: #001c72;
`;

export const Button = styled.div`
  margin: 0 auto;
  background: #bc2e3e;
  border-radius: 4px;
  width: 308px;
  height: 56px;
  padding: 18px 40px 12px;
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background: #c95865;
  }
`;

export const AgreementsText = styled.div`
  a {
    text-decoration: none;
    color: #bc2e3e !important;
  }
`;
