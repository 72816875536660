import React, { useCallback, useContext, useEffect } from 'react';
import * as S from './RepurchaseConfirmation.styles';
import { confirmRepurchase, getUserDetails } from '../../utils/user';
import useLocalStorage from '../../hooks/useLocalStorage';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import { formatPrice } from '../../utils/formatPrice';
import { replaceUrlState } from '../../utils/replaceUrlState';
import { useCount } from '../../context/CountContext';
import useAuth from '../../hooks/useAuth';
import LoadingCover from '../LoadingCover/LoadingCover';
import { Builder } from '@builder.io/react';
import { addPrefixToKey } from '../../utils/addPrefixToKey';
import { fetchUsersActiveSubscriptions } from '../../utils/activeSubscriptions';

const RepurchaseConfirmationV1 = props => {
  const { agreementsText } = props;
  const { state } = useCount();
  const { repurchaseQuantity: quantity } = state;

  const {
    onetime,
    subscription,
    currentCategory,
    currentProduct,
    bumpOfferIndex,
    bumpOfferChecked,
    subscriptionList,
    setSubscriptionList,
    userDetails,
    setUserDetails,
    extraObjects,
    setExtraObjects,
    setCurrentLayer
  } = useContext(FunnelContext);
  const [taxRate, setTaxRate] = useLocalStorage('taxRate', 0);
  const [extraObjects2, setExtraObjects2] = useLocalStorage('extraObjects', {});

  const isSubscription = currentCategory === 'subscriptions';
  const { products, bumpoffers } = isSubscription ? subscription : onetime;
  const product = products.find((el, i) =>
    isSubscription
      ? currentProduct === `subscription_${i}`
      : currentProduct === `onetime_${i}`
  );
  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });
  const savings = ProductPrice({
    type: 'savings',
    currentCategory,
    currentProduct
  });

  const bumpOfferPrice = bumpOfferChecked
    ? bumpoffers[bumpOfferIndex].checkoutData.discounted_price
    : 0;
  const subTotal = discountedPrice * quantity + bumpOfferPrice;
  const savingsTotal = bumpOfferChecked
    ? bumpoffers[bumpOfferIndex].checkoutData.split_price + savings
    : 0;
  const tax = taxRate * subTotal;

  const { token, loading } = useAuth();

  const getUserInfo = useCallback(async () => {
    const activeSubscriptions = await fetchUsersActiveSubscriptions(token);
    if (!activeSubscriptions?.data) {
      replaceUrlState(
        `${window.location.origin + window.location.pathname}?layer=error`
      );
      setCurrentLayer('error');
      return;
    }
    const [firstItem = {}] = activeSubscriptions.data?.data || [];
    const { taxAmount, subscriptionAmount } = firstItem;
    await setTaxRate(taxAmount / subscriptionAmount);
    await setSubscriptionList(activeSubscriptions.data?.data);

    const userDetails = await getUserDetails({
      bearerToken: token
    });
    if (!userDetails) {
      replaceUrlState(
        `${window.location.origin + window.location.pathname}?layer=error`
      );
      setCurrentLayer('error');
      return;
    }
    setUserDetails(userDetails);

    setExtraObjects({
      ...extraObjects,
      firstname: firstItem?.shippingAddress.firstName
    });
  }, [
    token,
    extraObjects,
    setExtraObjects,
    setUserDetails,
    setTaxRate,
    setSubscriptionList,
    setCurrentLayer
  ]);

  useEffect(() => {
    if (!token || loading || subscriptionList?.length) return;
    void getUserInfo();
  }, [token, loading, getUserInfo, subscriptionList?.length]);

  if ((loading || !subscriptionList?.length) && !Builder.isEditing) {
    return <LoadingCover />;
  }

  return (
    <>
      <S.Check>
        <S.CheckPosition>
          <S.Text>Subtotal</S.Text>
          <S.Text>{formatPrice(subTotal)}</S.Text>
        </S.CheckPosition>
        <S.CheckPosition>
          <S.Text>Taxes</S.Text>
          <S.Text>{formatPrice(tax)}</S.Text>
        </S.CheckPosition>
        <S.CheckPosition>
          <S.Text>Total Savings</S.Text>
          <S.Text>{formatPrice(savingsTotal)}</S.Text>
        </S.CheckPosition>
        <S.Total>
          <S.Text>Total</S.Text>
          <S.Text>{formatPrice(subTotal + tax)}</S.Text>
        </S.Total>
      </S.Check>
      <S.AgreementsText
        dangerouslySetInnerHTML={{ __html: agreementsText }}
      ></S.AgreementsText>
      <S.Button
        onClick={async () => {
          const variantIds = [{ variant_id: product.data.variantId, quantity }];
          bumpOfferChecked &&
            variantIds.push({
              variant_id: bumpoffers[bumpOfferIndex].data.variantId,
              quantity: 1
            });

          await confirmRepurchase({
            bearerToken: token,
            data: {
              variant_ids: variantIds,
              discount_codes: product.discount_code_override
            }
          }).then(r => {
            if (r.status === 200) {
              setTimeout(() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              });
              const { billingAddress, shippingAddress } = userDetails;
              const billingAddressPrefixed = addPrefixToKey(
                billingAddress,
                'billing'
              );
              const shippingAddressPrefixed = addPrefixToKey(
                shippingAddress,
                'shipping'
              );
              setExtraObjects2({
                ...extraObjects2,
                ...billingAddressPrefixed,
                ...shippingAddressPrefixed
              });
              replaceUrlState(
                `${window.location.origin +
                  window.location.pathname}?layer=success`
              );
              setCurrentLayer('success');
            } else {
              replaceUrlState(
                `${window.location.origin +
                  window.location.pathname}?layer=error`
              );
            }
          });
        }}
      >
        Pay now
      </S.Button>
    </>
  );
};

export default RepurchaseConfirmationV1;
